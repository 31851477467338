import React from 'react';
import { ActionType } from 'utils/types';
import { ListItemProps } from 'pages/shared/setItemsSelectionForm/listItem/ListItem.consts';
import {
  Item,
  RowContainer,
  StyledIcon,
  StyledIconButtonText,
  StyledToggleSwitch,
  ViewItem,
} from 'pages/shared/setItemsSelectionForm/listItem/ListItem.style';


const ListItemView = ({ item, actionType, onClick, onExcludeClick, className, disabled, itemFormatter, isDoe= false }: ListItemProps) => {
  const actionTypeToIcon: Record<ActionType, any> = {
    [ActionType.Add]: onClick && (
      <StyledIconButtonText disabled={disabled} onClick={() => onClick(ActionType.Add, item)} data-title="Add">
        <StyledIcon disabled={disabled} height={16} width={16} name="plusNew" />
      </StyledIconButtonText>
    ),
    [ActionType.Remove]: onClick && (
      <StyledIconButtonText disabled={isDoe ? false : disabled} onClick={() => onClick(ActionType.Remove, item)} data-title="Remove">
        <StyledIcon disabled={isDoe ? false : disabled} height={16} width={16} name="minusNew" />
      </StyledIconButtonText>
    ),
    [ActionType.AddExcluded]: onExcludeClick && (
      <StyledToggleSwitch
        checked
        disabled={disabled}
        size="medium"
        onClick={() => onExcludeClick(ActionType.AddExcluded, item)}
      />
    ),
    [ActionType.RemoveExcluded]: onExcludeClick && (
      <StyledToggleSwitch
        size="medium"
        disabled={disabled}
        onClick={() => onExcludeClick(ActionType.RemoveExcluded, item)}
      />
    ),
  };

  return (
    <ViewItem className={className} data-automation-id="list-item">
      <RowContainer tabIndex={0} disabled={actionType === ActionType.RemoveExcluded || disabled || item.status === 'inactive'}>{itemFormatter(item)}</RowContainer>
      {actionType && actionTypeToIcon[actionType]}
    </ViewItem>
  );
};

export default ListItemView;
