
import styled from '@emotion/styled';
import { Icon } from 'components/shared/icon';
import typography, { newTypography } from 'styles/typography';
import { ThemeProps } from '../termsConditions/TermsConditions.consts';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import Table from 'components/shared/table/CampaignTable';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';


export const ProductsPageContainer = styled(NewPageContainer)`
  width: 100%;
  max-width: 1416px;
  margin: 0px auto;
`;

export const StyledHeaderProducts = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px 0 ${typography.unit * 6}px;
`;

export const HeaderContainer = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
`;

export const ProductActions = styled.div<ThemeProps>`
  display: flex;
`;

export const ProductFilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: ${newTypography.primaryFont};
  border-top: 6px solid #f6bf35;
  border-radius: 8px 8px 0 0;
  padding: 36px;
  justify-content:space-between;
  background: ${({ theme }) => newGlobalTheme.colors.global.tileBackground};
`;

export const ProductSearchTextField = styled(NewSearchTextField)`
  margin-top: auto;
`;

export const StyledLoaderContainerProduct = styled.div<{ isEmpty: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '50%' : '100%')};
`;

export const ProductsStyledTable = styled(Table)`
  padding: 0 0;
  margin: 0 ${typography.unit * 1}px;
  border: 0;
  border-radius: 0;

  .MuiDataGrid-columnHeaderTitle  {
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-weight: 700 !important;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row {
    cursor: pointer !important;
    margin-bottom: 0 !important;
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row:hover {
    background-color: ${newGlobalTheme.colors.global.tileBackground};
  }
`;