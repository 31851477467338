import React, { useEffect, useRef, useState } from 'react';
import { ButtonContainedCancel, Save } from 'components/shared/button';
import { useSelector } from 'react-redux';
import { ApprovalStatus, OrderDirection } from 'utils/types';
import { BaseOffer, BaseOfferVersion } from 'utils/types/offers';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { offersSelection } from 'app/genericSlices/offers';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { store } from 'app/store';
import {
  NoOffers,
  OffersContainer,
  OfferSelectionContainer,
  OfferSelectionFooter,
  OffersList,
  StyledModal,
  StyledOfferItem,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/OfferSelection.style';
import OfferSelectionFilterBar from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/components/offerSelectionFilterBar/OfferSelectionFilterBar';
import { OfferSelectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/OfferSelection.consts';
import { FetchPolicies } from 'utils/types/common';

const OfferSelection = ({ selectedOffer, onSave, onCancel, externalVoucherCampaign = false, isLocalCampaign}: OfferSelectionProps) => {
  const [selected, setSelected] = useState(selectedOffer);
  const [offers, setOffers] = useState<BaseOffer[]>([]);
  const [hasFetchedSelectedOfferData, setHasFetchedSelectedOfferData] = useState(false);
  const [isSelectedOfferInOfferList, setIsSelectedOfferInOfferList] = useState(false);
  const [hasMoreOffers, setHasMoreOffers] = useState(false);
  const initialSelectedOffer = useRef<BaseOfferVersion | null>(selectedOffer || null);

  let { filters } = useSelector(offersSelection.offersState);
  if (filters.zone && filters.zone.length > 0 && typeof filters.zone[0] === 'object') {
    filters = {
      ...filters,
      zone: filters.zone.map((zone: { id: any }) => Number(zone.id)),
    };
  }
  if (filters.zone && filters.zone.length > 0) {
    filters = {
      ...filters,
      zone: filters.zone.map((zone: any) => Number(zone)),
    };
  }
  if (externalVoucherCampaign) {
    filters = {
      ...filters,
      [OfferFilters.TemplateType]: ['14'],
    };
  }

  const { data: selectedBaseOfferData } = useQuery(offersGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: initialSelectedOffer?.current?.baseOfferId,
    },
    skip: initialSelectedOffer.current === null,
    onCompleted: () => {
      setHasFetchedSelectedOfferData(true);
    }
  });

  const availableStatuses = [ApprovalStatus.Approved, ApprovalStatus.PendingApproval, ApprovalStatus.Draft];
  const { data, loading, error, fetchMore } = useQuery(offersGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: offersSelection.getFilters({
          [OfferFilters.Zone]: [],
          [OfferFilters.TemplateType]: {},
          [OfferFilters.Tags]: {},
          [OfferFilters.SearchQuery]: '',
          ...filters,
          [OfferFilters.Status]:
            filters[OfferFilters.Status]?.length === 0 ? availableStatuses : filters[OfferFilters.Status],
        }),
        order: { id: OrderDirection.DESC },
        limit: 18,
        offerSource: OfferSource.VCE,
      },
    },
  });
  const {
    getOffers: { items, total },
  } = data || { getOffers: { items: [], total: 0 } };
  useToastError(error, 'Error loading offers');

  const selectedFilters = filters[OfferFilters.Status].length === 0 
    ? availableStatuses
    : filters[OfferFilters.Status];
  useEffect(() => {
    if (initialSelectedOffer.current!==null && items.length > 0 && selectedFilters.includes(initialSelectedOffer.current.status)) {
      const selectedOfferInList = items.find((offer: any) => offer.id === initialSelectedOffer.current.baseOfferId);
      if (selectedOfferInList) {
        setOffers(()=>{
          return [selectedOfferInList, ...items.filter((offer: any) => offer.id !== selectedOfferInList.id)];
        });
        setIsSelectedOfferInOfferList(true);
      }
      else if(hasFetchedSelectedOfferData && selectedBaseOfferData){
        setOffers(()=>{
          return [selectedBaseOfferData.getOffer, ...items.filter((offer: any) => offer.id !== selectedBaseOfferData.getOffer.id)];
        });
        setIsSelectedOfferInOfferList(false);
      }
      else {
        setOffers(items);
      }
    } else {
      setOffers(items);
    }
  }, [data, selected, items, hasFetchedSelectedOfferData, selectedBaseOfferData]);

  useEffect (() => {
    if (initialSelectedOffer?.current!==null && selectedFilters.includes(initialSelectedOffer.current.status)) {
      const condition = (isSelectedOfferInOfferList && offers.length < total) || (!isSelectedOfferInOfferList && offers.length - 1 < total);
      setHasMoreOffers(condition);
    }
    else {
      setHasMoreOffers(offers.length < total ? true : false);
    }
  }, [offers, isSelectedOfferInOfferList]);

  const resetFilters = () => {
    store.dispatch(
      offersSelection.actions.setFilters({
        [OfferFilters.Status]: [ApprovalStatus.Approved],
        [OfferFilters.Zone]: filters[OfferFilters.Zone],
        [OfferFilters.TemplateType]: {},
        [OfferFilters.Tags]: {},
        [OfferFilters.SearchQuery]: '',
      }),
    );
  };

  const fetchNextOffers = async () => {
    if (hasMoreOffers) {
      const adjustedOffset = selected ? (isSelectedOfferInOfferList ? offers.length : offers.length - 1) : offers.length;
      await fetchMore({
        variables: {
          data: {
            filters: offersSelection.getFilters({
              [OfferFilters.Zone]: filters[OfferFilters.Zone],
              [OfferFilters.TemplateType]: {},
              [OfferFilters.Tags]: {},
              [OfferFilters.SearchQuery]: '',
              ...filters,
              [OfferFilters.Status]:
                filters[OfferFilters.Status]?.length === 0 ? availableStatuses : filters[OfferFilters.Status],
            }),
            offset: adjustedOffset,
            limit: 18,
            order: { id: OrderDirection.DESC },
            offerSource: OfferSource.VCE,
          },
        },
      });
    }
  };

  return (
    <StyledModal title="Offer Selection" withLoader={false}>
      <OfferSelectionContainer>
        <OfferSelectionFilterBar total={total} displayTemplate={!externalVoucherCampaign} />
        <OffersContainer id="offers-modal-scroll">
          { !offers  ? (
            <StyledLoader size={LoaderSize.Large} />
          ) : (
            <>
              {!loading && total === 0 ? (
                <NoOffers>No Available Offers</NoOffers>
              ) : (
                <OffersList
                  scrollableTarget="offers-modal-scroll"
                  dataLength={offers.length}
                  next={fetchNextOffers}
                  hasMore={hasMoreOffers}
                  loader={<StyledLoader size={LoaderSize.Large} />}
                >
                  {offers?.map((offer: BaseOffer) => {
                  const version = offer.versions && offer.versions.length > 0 ? offer.versions[0] : null;
                  if (!version) {
                    return null;
                  }
                  return (
                    <StyledOfferItem
                      key={offer.id}
                      offer={version}
                      isSelected={selected?.baseOfferId === offer.id}
                      onClick={() => setSelected(version)}
                      isLocalCampaign={isLocalCampaign}
                      selectedOffer={selected || undefined}
                    />
                  );
                })}
                </OffersList>
              )}
            </>
          )}
        </OffersContainer>
        <OfferSelectionFooter>
          <ButtonContainedCancel
            onClick={() => {
              resetFilters();
              onCancel();
            }}
          >
            Cancel
          </ButtonContainedCancel>
          <Save
            disabled={!selected}
            onClick={() => {
              resetFilters();
              onSave(selected);
            }}
          >
            Save
          </Save>
        </OfferSelectionFooter>
      </OfferSelectionContainer>
    </StyledModal>
  );
};

export default OfferSelection;
