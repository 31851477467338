import React, { useEffect, useState } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { Loader } from 'components/shared/loader';
import { store } from 'app/store';
import { useSelector } from 'react-redux';
import { OrderDirection } from 'utils/types';
import { BaseOffer } from 'utils/types/offers';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { doeOffersPage, vceOffersPage } from 'app/genericSlices/offers';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import useQueryInterval from 'hooks/use-query-polling';
import { Actions, StyledHeader, StyledLoader, StyledTotalAmountWithGrid } from '../../shared/shared.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { OfferFilterBar } from '../offerManagement/components/offerFilterBar/OfferFilterBar';
import { OfferLoaderWrapper, OffersGrid, OffersGridContainer, StylesCSVButton, TitleActionButtonsContainer } from '../offerManagement/Offers.style';
import OfferItem from '../offerManagement/components/offerItem/OfferItem';
import { OfferSource } from '../offerManagement/Offers.const';
import doeOffersGqls from './DoeOffers.gqls';
import { client } from 'app/apollo';
import { NewStyledDownload } from 'pages/shared/downloadCsvButton/DownloadCsvButton.style';
import { generateColumnNames, generateOfferRow } from 'utils/offer';
import { downloadCSV } from 'utils/download';
import { marketConfig } from 'app/slices/config';
import { getOffersForReport } from 'utils/api/offers';

const DoeOffers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { order, filters } = useSelector(doeOffersPage.offersState);
      const { config } = useSelector(marketConfig);
      const {
        primaryLanguage,
        secondaryLanguage,
      } = config;
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(doeOffersGqls.queries.getOffersScreenData, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: doeOffersPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        offerSource: OfferSource.DOE,
      },
    },
  });
  const {
    getDoeOffers: { items: doeOffers, total },
  } = data || { getDoeOffers: { items: undefined } };
  useToastError(error, 'Error loading doeOffers');
  useQueryInterval(600000, startPolling, stopPolling);

  const fetchNextDoeOffers = async () => {
    if (doeOffers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: doeOffersPage.getFilters(filters),
            offset: doeOffers.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
            offerSource: OfferSource.DOE,
          },
        },
      });
    }
  };
  const downloadOfferData = async () => {
    setIsLoading(true);
    const rows = generateColumnNames(primaryLanguage, secondaryLanguage);
    const allFilterdOffers = await getOffersForReport({
      filters: doeOffersPage.getFilters({
        ...filters,
      }),
      offerSource: OfferSource.DOE,
      order,
      limit: 9999,
      offset: 0,
    });
    const offersRows = allFilterdOffers.map((offer) => generateOfferRow(offer, config));
    rows.push(...offersRows);
    downloadCSV(rows, `OfferData`);
    setIsLoading(false);
  };

  useEffect(() => {
    store.dispatch(termsConditionsSelection.loadTerms());
    return () => {
      const offerKeys = Object.keys(client.cache.extract().ROOT_QUERY).filter((k) => k.includes('getDoeOffers'));
      const method = (existingData: any) => {
        return { ...existingData, items: (existingData?.items || []).slice(0, 24) };
      };
      const fields = {};
      offerKeys.forEach((k) => {
        Object.assign(fields, {
          [k]: method,
        });
      });
      client.cache.modify({
        id: 'ROOT_QUERY',
        fields: fields,
      });
    };
  }, []);
  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TitleActionButtonsContainer
          style={{ display: 'flex', justifyContent: 'space-between' }}
          data-automation-id="actions"
        >
          <TextPageTitle text={tabName.DoeOffers} />
          <StylesCSVButton onClick={() => downloadOfferData()} data-title="Download CSV">
            <NewStyledDownload width={16} height={16} name="newDownload" />
          </StylesCSVButton>
        </TitleActionButtonsContainer>
        <Actions data-automation-id="actions">
          <OfferFilterBar offerSource={OfferSource.DOE} total={total} />
        </Actions>
      </StyledHeader>
      {!doeOffers ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          <StyledTotalAmountWithGrid amount={total} />
          {Boolean(doeOffers?.length) && (
            <OffersGrid id="offers-scroll">
              <OffersGridContainer
                scrollableTarget="offers-scroll"
                dataLength={doeOffers?.length}
                next={fetchNextDoeOffers}
                hasMore={doeOffers?.length < total}
                loader={<Loader />}
              >
                {isLoading && (
                  <OfferLoaderWrapper>
                    <Loader role="status" aria-label="Loading more offers" aria-busy={true} size={LoaderSize.Large} />
                  </OfferLoaderWrapper>
                )}
                {doeOffers?.map((offer: BaseOffer) => {
                  return <OfferItem key={offer.id} offer={offer} offerSource={OfferSource.DOE} />;
                })}
              </OffersGridContainer>
              <Tooltip id={'offer-lock-icon'} onHover content="Locked for changes" place="bottom" />
            </OffersGrid>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default DoeOffers;
