import styled from '@emotion/styled';
import Checkbox from 'components/shared/checkbox/Checkbox';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const Periods = styled.div<StyledWithThemeProps>`
  h5 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: ${typography.unit * 5}px;
  }
  margin-bottom: ${typography.unit * 8}px;
  overflow: scroll;
  height: 250px
`;

export const Period = styled.div`
  display: flex;
  gap: ${typography.unit * 2}px;
  margin-bottom: ${typography.unit * 3}px;
  label {
    margin-right: 5px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  align-items: flex-start;
`;
