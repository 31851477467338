import React, { useEffect, useState } from 'react';
import { tabName } from 'components/header/Header.consts';
import { Loader } from 'components/shared/loader';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import { FormMode, OrderDirection } from 'utils/types';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { BaseOffer } from 'utils/types/offers';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { vceOffersPage } from 'app/genericSlices/offers';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import useQueryInterval from 'hooks/use-query-polling';
import {
  ActionButtonsContainer,
  NewOffersGridContainer,
  OfferAddNewButton,
  OfferFilterHeader,
  OfferLoaderWrapper,
  OfferNewStyledHeader,
  OfferPageContainer,
  OfferPageTitle,
  OffersGrid,
  OfferTitleActionHeader,
  StylesCSVButton,
  TitleActionButtonsContainer,
  ZonesButton,
  ZonesIcon
} from './Offers.style';
import { StyledLoader } from '../../shared/shared.style';
import OfferItem from './components/offerItem/OfferItem';
import { OfferFilterBar } from './components/offerFilterBar/OfferFilterBar';
import offersGqls from './Offers.gqls';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { OfferSource } from './Offers.const';
import { StyledTotalAmountWithGrid } from './../components/offerFilterBar/OfferFilterBar.style';
import { client } from 'app/apollo';
import { NewStyledDownload } from 'pages/shared/downloadCsvButton/DownloadCsvButton.style';
import { generateColumnNames, generateOfferRow } from 'utils/offer';
import { marketConfig } from 'app/slices/config';
import { getOffersForReport } from 'utils/api/offers';
import { downloadCSV } from 'utils/download';

const Offers = () => {
  const { order, filters } = useSelector(vceOffersPage.offersState);
  const [isOfferArchieving, setIsOfferArchieving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    const { config } = useSelector(marketConfig);
    const {
      primaryLanguage,
      secondaryLanguage,
    } = config;
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(offersGqls.queries.getOffersScreenData, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: vceOffersPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        offerSource: OfferSource.VCE,
      },
    },
  });
  const {
    getOffers: { items: offers, total },
  } = data || { getOffers: { items: undefined } };
  useToastError(error, 'Error loading offers');
  useQueryInterval(600000, startPolling, stopPolling);

  const onCreateNewOffer = () => {
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { mode: FormMode.New, offerSource: OfferSource.VCE },
      }),
    );
  };

  const onZonesButtonClick = () => {
    store.dispatch(
      openModal({
        modal: Modals.ZonesManagementModal,
      }),
    );
  };

  const fetchNextOffers = async () => {
    if (offers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: vceOffersPage.getFilters(filters),
            offset: offers.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
            offerSource: OfferSource.VCE,
          },
        },
      });
    }
  };

  const downloadOfferData = async () => {
    setIsLoading(true);
    const rows = generateColumnNames(primaryLanguage, secondaryLanguage);
    const allFilterdOffers = await getOffersForReport({
      filters: vceOffersPage.getFilters({
        ...filters,
      }),
      offerSource: OfferSource.VCE,
      order,
      limit: 9999,
      offset: 0,
    });
    const offersRows = allFilterdOffers.map((offer) => generateOfferRow(offer, config));
    rows.push(...offersRows);
    downloadCSV(rows, `OfferData`);
    setIsLoading(false);
  };

  useEffect(() => {
    store.dispatch(termsConditionsSelection.loadTerms());
    return () => {
      const offerKeys = Object.keys(client.cache.extract().ROOT_QUERY).filter((k) => k.includes('getOffers'));
      const method = (existingData: any) => {
        return { ...existingData, items: (existingData?.items || []).slice(0, 24) };
      };
      const fields = {};
      offerKeys.forEach((k) => {
        Object.assign(fields, {
          [k]: method,
        });
      });
      client.cache.modify({
        id: 'ROOT_QUERY',
        fields: fields,
      });
    };
  }, []);

  return (
    <OfferPageContainer>
      <OfferNewStyledHeader data-automation-id="header">
        <OfferTitleActionHeader>
          <OfferPageTitle text={tabName.Offers} tabIndex={0} />
          <TitleActionButtonsContainer data-automation-id="actions">
            <ActionButtonsContainer>
              <MarketConfigurationGuard
                configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
              >
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  <ZonesButton
                    onClick={onZonesButtonClick}
                    tabIndex={0}
                    aria-label="Offer Zones Button"
                    data-title="Zone Management"
                  >
                    <ZonesIcon name="newMapPin" width={14} height={19}></ZonesIcon>
                  </ZonesButton>
                </RoleGuard>
              </MarketConfigurationGuard>
              <StylesCSVButton onClick={() => downloadOfferData()} data-title="Download CSV">
                <NewStyledDownload width={16} height={16} name="newDownload" />
              </StylesCSVButton>
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                <OfferAddNewButton onClick={onCreateNewOffer} aria-label="Add New Offer" />
              </RoleGuard>
            </ActionButtonsContainer>
          </TitleActionButtonsContainer>
        </OfferTitleActionHeader>
        <OfferFilterHeader>
          <OfferFilterBar offerSource={OfferSource.VCE} total={total} />
        </OfferFilterHeader>
      </OfferNewStyledHeader>

      {!offers ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          {Boolean(offers?.length) && (
            <OffersGrid id="offers-scroll">
              {isOfferArchieving && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <NewOffersGridContainer
                scrollableTarget="offers-scroll"
                dataLength={offers?.length}
                next={fetchNextOffers}
                hasMore={offers?.length < total}
                loader={<Loader />}
              >
                {isLoading && (
                  <OfferLoaderWrapper>
                    <Loader role="status" aria-label="Loading more offers" aria-busy={true} size={LoaderSize.Large} />
                  </OfferLoaderWrapper>
                )}
                {offers?.map((offer: BaseOffer) => {
                  return (
                    <OfferItem
                      key={offer.id}
                      offer={offer}
                      setIsOfferArchieving={setIsOfferArchieving}
                      offerSource={OfferSource.VCE}
                    />
                  );
                })}
              </NewOffersGridContainer>
              <Tooltip id={'offer-lock-icon'} onHover content="Locked for changes" place="left" />
            </OffersGrid>
          )}
        </>
      )}
    </OfferPageContainer>
  );
};

export default Offers;
