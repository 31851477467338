import React from 'react';
import { marketConfig } from 'app/slices/config';
import { BaseOffer, OfferTemplates } from 'utils/types/offers';
import {
  OfferVersion,
  NoOfferText,
  StyledIcon,
  NewOffersGridItemBackdrop,
  NewOffersGridItemActions,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem.style';
import { OfferVersionItemProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem.consts';
import { Modals, openModal, modal as modalSlice, setData } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { store } from 'app/store';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import doeOffersGqls from 'pages/offers/doeOffers/DoeOffers.gqls';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { StyledButtonText } from 'pages/offers/offerManagement/components/offerItem/OfferItem.style';
import { FetchPolicies } from 'utils/types/common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';
import { OfferHeading, RowFooter } from './ViewOfferItem.style';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

const OfferVersionItem = ({
  offer,
  campaign,
  onClick,
  className,
  isSelected = false,
  isLocalCampaign,
  selectedOffer
}: OfferVersionItemProps) => {
  const { props, data: modalData } = useSelector(modalSlice);
  const query = campaign?.offerSource !== OfferSource.DOE ? offersGqls.queries.getById : doeOffersGqls.queries.getById;
  const [load] = useLazyQuery(query, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });
  const { data: realoffer } = useQuery(query, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });
  const { config } = useSelector(marketConfig);
  const primaryTranslation = realoffer?.getOffer?.versions[0]?.translationsMap[config.primaryLanguage];

  return (
    <OfferVersion
      className={className}
      onClick={onClick}
      offer={offer}
      campaign={campaign}
      data-automation-id="offer-version"
    >
      {offer ? (
        <>
          {isSelected && (
            <NewOffersGridItemBackdrop>
              <NewOffersGridItemActions>
                <StyledIcon name="newCheckmark" />
              </NewOffersGridItemActions>
            </NewOffersGridItemBackdrop>
          )}
          <Card sx={{ width: 254, height: 320, boxShadow: '0px 0px 5.3px 1px #00000029' }}>
            <div style={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #D6D6D6' }}>
              <CardMedia
                component="img"
                alt={`${primaryTranslation?.image?.name}`}
                height="140"
                image={`${primaryTranslation?.image?.file}`}
                crossOrigin="anonymous"
                sx={{ width: '116px', objectFit: 'scale-down' }}
              />
            </div>
            <CardContent>
              <OfferHeading>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '400',
                    fontFamily: 'Speedee',
                    marginTop: '8px',
                    marginBottom: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {primaryTranslation?.title}
                </Typography>
              </OfferHeading>
              <Typography sx={{ fontSize: '12px', fontWeight: '700', fontFamily: 'Speedee', marginBottom: '8px' }}>
                ID {offer?.baseOfferId}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  fontFamily: 'Speedee',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {OfferTemplates[offer.templateValues.templateType as keyof typeof OfferTemplates]?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  fontFamily: 'Speedee',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  height: '17px;',
                }}
              >
                {primaryTranslation?.subtitle}
              </Typography>

              <RowFooter>
                <div>
                  <NewStatusLabel type={offer?.status} />
                </div>
                <div>
                  <StyledButtonText
                    onClick={(event) => {
                      event.stopPropagation();
                      load().then(({ data }) => {
                        store.dispatch(
                          openModal({
                            modal: Modals.OfferModal,
                            data: {
                              viewOnly: true,
                              campaign: { ...props.campaign },
                            },
                            props: {
                              offer: data.getOffer,
                              campaign: { ...props.campaign },
                              viewOnly: true,
                              mode: FormMode.View,
                              onClose: () =>
                                modalData.campaign
                                  ? store.dispatch(
                                      openModal({
                                        modal: Modals.OfferSelectionModal,
                                        data: {
                                          ...modalData,
                                        },
                                        props: {
                                          ...modalData,
                                          ...(selectedOffer && { selectedOffer }),
                                          onSave: (offerVersion: BaseOffer) => {
                                            store.dispatch(
                                              setData({
                                                data: {
                                                  isFormDirty: true,
                                                },
                                              }),
                                            );
                                            store.dispatch(
                                              openModal({
                                                modal: Modals.CampaignModal,
                                                data: {
                                                  mode: modalData.mode,
                                                  campaign: { ...modalData.campaign, offerVersion },
                                                  clickSelectOffer: false,
                                                },
                                                props: {
                                                  mode: modalData.mode,
                                                  campaign: { ...modalData.campaign, offerVersion },
                                                  clickSelectOffer: false,
                                                  isLocalCampaign,
                                                },
                                              }),
                                            );
                                          },
                                          onCancel: () => {
                                            store.dispatch(
                                              openModal({
                                                modal: Modals.CampaignModal,
                                                data: {
                                                  mode: modalData.mode,
                                                  campaign: {
                                                    ...modalData.campaign,
                                                    ...props.campaign,
                                                  },
                                                  clickSelectOffer: false,
                                                },
                                                props: {
                                                  mode: modalData.mode,
                                                  campaign: {
                                                    ...modalData.campaign,
                                                    ...props.campaign,
                                                  },
                                                  clickSelectOffer: false,
                                                  isLocalCampaign,
                                                },
                                              }),
                                            );
                                          },
                                        },
                                      }),
                                    )
                                  : store.dispatch(
                                      openModal({
                                        modal: Modals.CampaignModal,
                                        props: { ...props, isLocalCampaign },
                                      }),
                                    ),
                            },
                          }),
                        );
                      });
                    }}
                  >
                    View Details
                  </StyledButtonText>
                </div>
              </RowFooter>
            </CardContent>
          </Card>
        </>
      ) : (
        <NoOfferText>No Offer Defined</NoOfferText>
      )}
    </OfferVersion>
  );
};

export default OfferVersionItem;
