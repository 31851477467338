import { MarketConfiguration } from 'app/slices/config';
import { OfferProps } from '../pages/offers/offerManagement/Offers.const';
import { getDoeImageById } from './api/images';
import { LanguageRecord } from './languages';
import { DoeImage } from './types/images';
import { BaseOffer, OfferTemplates } from './types/offers';

export const getOfferStatus = (offer: BaseOffer | OfferProps) => {
  if (!offer?.versions || !offer?.versions[0]?.status) {
    return null;
  }
  return offer.versions[0].status;
};

export const handleOfferImpactChange = (offers: OfferProps[]) => {
  return offers?.map((offer: OfferProps) => ({
    id: offer?.id,
    entityId: offer?.id,
    title: offer?.versions[0]?.translations[0]?.title,
    status: getOfferStatus(offer),
    isDefault: !offer?.versions[0]?.term?.originalTermId,
  }));
};

export const viewImageClicked = async (id: number) => {
  const { data: doeImageData } = await getDoeImageById(Number(id));
  const imageData: DoeImage = { ...doeImageData.getDoeImage } as any;
  return imageData;
};

export const generateColumnNames = (primaryLanguage: string, secondaryLanguage: string) => {
  return [
    [
      `Offer Template`,
      `Image URL ${LanguageRecord[primaryLanguage]}`,
      `Image URL ${LanguageRecord[secondaryLanguage]}`,
      `Title ${LanguageRecord[primaryLanguage]}`,
      `Title ${LanguageRecord[secondaryLanguage]}`,
      `POS Title ${LanguageRecord[primaryLanguage]}`,
      `POS Title ${LanguageRecord[secondaryLanguage]}`,
      `Subtitle ${LanguageRecord[primaryLanguage]}`,
      `Subtitle ${LanguageRecord[secondaryLanguage]}`,
      `Description ${LanguageRecord[primaryLanguage]}`,
      `Description ${LanguageRecord[secondaryLanguage]}`,
      `Term and Condition ${LanguageRecord[primaryLanguage]}`,
      `Term and Condition ${LanguageRecord[secondaryLanguage]}`,
      `Tags`,
      `Group ID`,
      `Point of Distribution`,
    ],
  ];
};

export const generateOfferRow = (
  offer: any,
  config: MarketConfiguration,
) => {
  const primaryLang = config.primaryLanguage;
  const secondaryLang = config.secondaryLanguage;
  const latestVesion = offer.versions[0];
  const primaryData = latestVesion.translationsMap[primaryLang] || {};
  const secondaryData = latestVesion.translationsMap[secondaryLang] || {};
  const primaryTerm = latestVesion.term?.translationsMap[primaryLang] || {};
  const secondaryTerm = latestVesion.term?.translationsMap[secondaryLang] || {};
  const tagNames = offer.tags?.map((tag: { name: any; }) => tag?.name) || '';
  const offerTemplateName = OfferTemplates[offer.templateType]?.name
  const commonFields = [
    offerTemplateName || '',
    primaryData.image?.file || '',
    secondaryData.image?.file || '',
    primaryData.title || '',
    secondaryData.title || '',
    primaryData.posTitle || '',
    secondaryData.posTitle || '',
    primaryData.subtitle || '',
    secondaryData.subtitle || '',
    primaryData.description || '',
    secondaryData.description || '',
    primaryTerm.content || '',
    secondaryTerm.content || '',
    tagNames,
    offer.segmentId || '',
    latestVesion.pointOfDistribution || ''
  ];

  return commonFields;

};