import React, { useEffect, useState } from 'react';
import { SortingKey } from 'utils/sorting';
import { useFormContext } from 'react-hook-form';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { ActionType } from 'utils/types';
import ListItem from 'pages/shared/setItemsSelectionForm/listItem/ListItem';
import { SelectedItemsAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedItemsAccordion/SelectedItemsAccordion.consts';
import { useItemsFilter } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedItemsAccordion/hooks/useItemsFilter';
import { Virtuoso } from 'react-virtuoso';
import { StyledItemsSelectionAccordion } from '../../SetItemsListPanel.style';
import ListItemView from 'pages/shared/setItemsSelectionForm/listItem/ListItemView';

const SelectedItemsAccordion = ({
  accordion,
  isSingleAccordionPanel,
  itemFormatter,
  onItemSelect,
  removeAll,
  searchValue,
  onExcludeItemClick,
  items,
  disabled: disabled,
  isDoe = false,
  sort,
}: SelectedItemsAccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const [sortingKey, setSortingKey] = useState(SortingKey.Az);
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const selectedItemsById = watch('selectedItemsById') ?? items ?? {};
  const { name, headline, sortOptions, searchItem } = accordion;
  const itemsBySearchValue = useItemsFilter(Object.values(selectedItemsById), searchValue, searchItem, sortingKey);

  const sortProducts = (products: any, sortType: string): any[] => {
    switch (sortType) {
      case 'AlphaAsc':
        return [...products].sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      case 'AlphaDesc':
        return [...products].sort((a, b) => b.name.trim().localeCompare(a.name.trim()));
      case 'PluAsc':
        return [...products].sort((a, b) => a.plu - b.plu);
      case 'PluDesc':
        return [...products].sort((a, b) => b.plu - a.plu);
      default:
        return itemsBySearchValue;
    }
  };

  return (
    <StyledItemsSelectionAccordion
      listName={name}
      headline={headline}
      showExpandArrow={!isSingleAccordionPanel}
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      onSortChange={(listName, key) => setSortingKey(key)}
      removeAll={removeAll ? () => removeAll(itemsBySearchValue) : null}
      sortOptions={sortOptions}
      disabled={disabled}
      height={0}
    >
      <Virtuoso
        data={sortProducts(itemsBySearchValue, sort)}
        itemContent={(index, item) => (
          <ListItemView
            key={`p-${item.id}`}
            item={item}
            actionType={ActionType.Remove}
            onClick={onItemSelect}
            disabled={
              isDoe &&
              item.nextStatusChangeWillBeAt !== null &&
              item.status === 'inactive'
            }
            itemFormatter={itemFormatter}
            onExcludeClick={onExcludeItemClick}
            isDoe={isDoe}
            className={name}
          />
        )}
      />
    </StyledItemsSelectionAccordion>
  );
};

export default SelectedItemsAccordion;
